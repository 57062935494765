.highlight {
  position: relative;
  ///background: #ff534a;
  background: rgba(255, 69, 0, 0.30);
  box-shadow: inset 0 0 0 1px #ff534a;
  transition: all 200ms ease;

  &::after {
    content: '';
    display: block;
    padding-bottom: 10px;
  }

  &::before {
    content: '';
    display: block;
    padding-top: 5px;
  }

  input[type=text] {
    opacity: 0.9;
    border: 1px solid #ff534a;
  }
}

.section-selector{
  width: 270px;
  background-color: #fff;
  border-radius: 3px;
  transition: all 0.1s ease;
  border: 1px solid #dde1e6;

  &.flexible{
    width: 100%;
  }

  &.minimized{
    width: 55px;

    .section-item{
      .title, .validation{
        display: none;
      }

      &:hover{
        .title{
          display: block;
          background: white;
          z-index: 3;
          padding: 9px;
          border: 1px solid #eef1f6;
          width: 120px;
          position: absolute;
          left: 53px;
          border-left: 0;
        }
      }
    }

    .section-footer{
      .actions{
        flex-direction: column;
        .action{
          margin-left: 5px;

          &+.action{
            margin-top: 5px;
          }
        }
      }
    }
  }

  .section-item{
    display: flex;
    align-items: center;
    height: 40px;
    transition: all 0.2s ease;
    justify-content: space-between;
    color: #79589F;
    cursor: pointer;
    text-decoration: none;
    position: relative;

    &.active{
      text-decoration: none;
      background-color: #F7F8FB;
      color: #61467f;
    }
    &.invalid{
      .validation{
        opacity: 1;
      }
    }
    &:hover{
      text-decoration: none;
      background-color: #F7F8FB;
      color: #61467f;
    }

    &:not(:last-child){
      border-bottom: 1px solid #EEF1F6;
    }

    .icon{
      font-size: 14px;
      padding-left: 20px;
      padding-right: 10px;
      width: 50px;
      min-width: 50px;
    }

    .title{
      line-height: 20px;
      font-size: 14px;
      width: 100%;
      white-space: nowrap;
      color: #3F3F44;
    }

    .validation{
      opacity: 0;
      padding-right: 20px;
      height: 100%;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: orange;
      transition: all 0.1s ease;
    }
  }

  .section-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #3F3F44;
    padding: 20px 10px 20px;
    border-bottom: 1px solid #EEF1F6;

    .company-name{
      margin-top: 10px;
    }

    .user{
      color: #96A3B6;
      font-weight: 200;
      margin-top: 5px;
    }
  }

  .section-footer{
    display: flex;
    align-items: center;
    padding: 10px;

    .actions{
      display: flex;
      width: 100%;

      .action{
        border: 1px solid #dde2e6;
        width: 22px;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        color: #a996c0;
        display: flex;
        cursor: pointer;
        min-width: 22px;
        transition: all 0.2s ease;
        margin-left: 5px;

        &:hover{
          color: #8d7ba4;
        }

        &.active{
          background: #8dcae9;
          color: white;
          border-color: #95a6d6;
        }

      }
    }
  }
}

.content {
  position: absolute;

  &.left {
    left: 0;
    top: 0;
    padding-right: 5px;
  }

  &.right {
    padding-left: 5px;
    right: 0;
    top: 0;
  }

  &.fixed-left {
    position: fixed;
    padding-right: 5px;
  }

  &.fixed-right {
    padding-left: 5px;
    position: fixed;
  }

  .loading {
    width: 300px;
    height: 200px;
  }

  .image {
    padding-top: 25px !important;
    width: 800px;
    max-height: 800px;
    overflow-y: auto;
  }

  .draggableArea {
    position: fixed;
    top: 0px;
    height: 30px;
    z-index: 999;
    width: 99%;
    background-color: #f7f8fb;
    cursor: grab;
    display: flex;
    align-items: center;
    justify-content: center;

    .fixed {
      position: fixed;
    }

    .title {
      font-size: 14px;
      color: gray;
    }

    .menu {
      position: absolute;
      left: 10px;
      top: 8px;
      //cursor: pointer;
    }

    .unpin {
      color: gray;
    }
  }

  .closePreview {
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 10px;
  }

  .content-wrapper {
    position: relative;
    padding: 10px;
    box-shadow: 0 3px 20px rgba(89, 105, 129, 0.3),
      0 1px 2px rgba(0, 0, 0, 0.05),
      0 0 0 1px rgba(89, 105, 129, 0.1);
    background-color: #fff;
    color: #252a2d;
    text-align: left;
    z-index: 3;
    cursor: default;


    &.small {
      width: 500px;
      height: auto;
    }

    &.medium {
      width: 650px;
      height: auto;
    }

    &.large {
      width: 800px;
      height: auto;
    }
  }

}

.checkbox{
  cursor: pointer;
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;

  &.w-100 {
    margin-right: 0;
  }
}

.checkbox__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  clip: rect(0, 0, 0, 0);

  &:checked ~ .checkbox__data {

    .checkbox__text,
    .checkbox__text--black {
      color: rgb(30, 55, 98);
      //font-weight: bold;
    }

    .checkbox__indicator {
      background-color: #97a0d3;
      color: #000000;

      &:before {
        opacity: 1;
        transform: rotate(45deg);
      }
    }
  }

  &:disabled ~ .checkbox__data {
    opacity: .5;
    cursor: auto;

   /*  &:hover {
      background: #f5f5f5;
    } */
  }
}

.checkbox__data {
  display: flex;
  //padding: 10px 12px;
  border-radius: 10px;
  box-sizing: border-box;
  align-items: center;
  transition: 0.3s;
}

.checkbox__indicator {
  position: relative;
  margin-right: 9px;
  width: 22px;
  min-width: 22px;
  height: 22px;
  background: #ffffff;
  border: 1px solid rgba(151, 160, 211, 0.5);
  box-sizing: border-box;
  align-self: flex-start;

  &:before {
    content: '';
    display: block;
    width: 6px;
    height: 12px;
    border: 2px solid #ffffff;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 2px;
    left: 7px;
    opacity: 0;
    transform: rotate(-105deg);
    transition: 0.3s;
  }
}

.checkbox__text {
  font-weight: normal;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 0px;
  color: rgb(30, 55, 98);
}

.inputs__flex {
  display: flex;
  flex-wrap: wrap;
}

.checkbox.large{
  .checkbox__indicator{
    height: 26px;
    min-width: 26px;
    width: 26px;

    &:before {
      width: 7px;
      height: 14px;
      top: 3px;
      left: 9px;
    }
  }
}

.checkbox__indicator.small{
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;

  &:before {
    width: 4px;
    height: 8px;
    top: 1.10px;
    left: 4px;
  }
}

.checkbox__indicator.small + .checkbox__text{
  font-size: 12px;
}

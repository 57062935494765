.reset-radio-selection {
  margin-left: 10px;
  background: white;
  border: 1px solid #dedede;
  color: grey;
  font-size: 12px;
  padding-top: 3px;
  transition: all 200ms ease;

  &:hover {
    background: #97a0d3;
    color: white;
    border-color: #7a81a7;
  }

  &:focus,
  &:active {
    outline: none;
  }
}

.radio-group-vertical{
  .reset-radio-selection{
    margin-left: 0;
    margin-top: 10px;
  }
}

.has-warning {
  position: relative;

  .help-block {
    font-size: 10px !important;
    margin-bottom: 2px !important;
    position: absolute;
    bottom: 0;
    right: 20px;
  }
}

.has-source-warning {
  position: relative;

    .radio-custom {
      &:disabled:checked {
        + .radio-custom-label {
          &::before {
              //background-color: #f9f9f9;
          }
        }
      }

      + .radio-custom-label {
        &::before {
          border-color: orange;
        }
      }
    }

   .source-control {
     border-color: rgba(255, 166, 0, 0.600);
   }

   .form-control {
     border-color: rgba(255, 166, 0, 0.600);
   }
}

.has-warning .form-control {
  border-color: red !important;
  color: red !important;
}

.has-warning fieldset {
  border-color: red !important;
}

.has-warning {
  .help-block {
    color: red !important;
  }
}

.form-control.total {
  font-size: 18px;
  background: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #9c9c9c;
  color: #737373;
  background: rgba(28, 173, 28, 0.055);

  &.success {
    border-color: #1cad1c !important;
    color: #1cad1c !important;
    background: rgba(28, 173, 28, 0.055) !important;
  }
}

.incorrect {
  font-size: 11px;
  color: red;
  margin-top: 5px;
  text-align: center;
}

.ask-cell {
  ask {
    position: absolute;
    bottom: 0;
    right: 20px;
  }

  .source-text {
    top: 0;
    position: absolute;
    right: 15px;
    font-size: 8px !important;
  }
}

.source-label {
  .source-text {
    top: 0;
    position: absolute;
    right: 15px;
    font-weight: normal;
  }
}

.ask-custom {
  >label {
    cursor: pointer;
  }

  input[type=checkbox] {
    display: none;
  }

  input[type=checkbox]+label:before {
    font-family: FontAwesome;
    display: inline-block;
    content: "\f00c";
    color: #15c515;
  }

  input[type=checkbox]:checked+label:before {
    content: "Ask";
    background-color: #ffffff;
    box-shadow: inset 0 0 0 1px rgba(121, 88, 159, 0.6);
    color: #79589F;
    padding: 2px 8px;
    border: 0 solid transparent;
    border-radius: 3px;
    font-size: 11px;
  }
}

.resend-custom {
  >label {
    cursor: pointer;
  }

  input[type=checkbox] {
    display: none;
  }

  input[type=checkbox]:checked+label:before {
    font-family: FontAwesome;
    display: inline-block;
    content: "\f00c";
    color: #15c515;
    box-shadow: none;
    border: none;
    background-color: transparent;
    font-size: 15px;
    padding: 0;
  }

  input[type=checkbox]+label:before {
    content: "Resend";
    background-color: #ffffff;
    box-shadow: inset 0 0 0 1px rgba(121, 88, 159, 0.6);
    border: 0 solid transparent;
    color: #79589F;
    padding: 4px 8px;
    border-radius: 3px;
    font-size: 11px;
  }
}

.ask-icon::before {
  display: inline-block;
  margin-right: .5em;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

.bvq-row{
  margin-top: 10px;
  margin-bottom: 10px;
}

.highlight-block{
  margin-bottom: 10px;
  border: 1px solid #e8ebf3;
  padding: 0 10px 0;
  background: rgba(247, 248, 252, 0.3);

  &.bottom{
    padding-bottom: 10px;
  }
  &.top{
    padding-top: 10px;
  }
}

.draft{
  background: orange;
  display: inline-block;
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 2px;
  color: white;
  font-size: 11px;
}
